import React from "react";

const FilterBadge = ({ label, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 rounded-full text-sm font-medium transition-all ${
      isActive
        ? "bg-[#0e9065] text-white"
        : "bg-gray-200 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
    }`}
  >
    {label}
  </button>
);

export default FilterBadge;
