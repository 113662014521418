import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import {
  getBaseByUserId,
  getLocationsByBaseId,
  getLocationsByUserId,
  getAllLocations,
  getLifecyclesByLocation,
  getRotationsByLifecycleId,
} from "./services/firebaseService";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const { user, permissions } = useContext(UserContext);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [baseId, setBaseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lifecycles, setLifecycles] = useState({});

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        // console.log("permissions", permissions);
        const userType = permissions?.id;
        let fetchedLocations = [];

        if (userType === "SUPER_ADMIN") {
          // console.log("Fetching all locations");
          fetchedLocations = await getAllLocations();
        } else if (userType === "UNIVERSITY" || userType === "ADMIN") {
          const base = await getBaseByUserId(user.id);
          if (base) {
            fetchedLocations = await getLocationsByBaseId(base.id);
            // console.log("base", base.id);
            setBaseId(base.id);
          }
        } else if (userType === "INSTITUTION") {
          fetchedLocations = await getLocationsByUserId(user.id);
          setBaseId(fetchedLocations[0].base);
        } else if (userType === "TRAINER") {
          // console.log("Fetching locations by user id");
          // console.log("user.id", user.id);
          fetchedLocations = await getLocationsByUserId(user.id);
          setBaseId(fetchedLocations[0].base);
        }

        // Fetch lifecycles for each location
        const lifecyclesMap = {};
        for (const location of fetchedLocations) {
          // console.log("location", location);
          const locationLifecycles = await getLifecyclesByLocation(location.id);
          if (locationLifecycles) {
            // console.log("locationLifecycles", locationLifecycles);
            const rotations = await getRotationsByLifecycleId(
              locationLifecycles.id
            );
            lifecyclesMap[location.id] = {
              ...locationLifecycles,
              stages: locationLifecycles.stages.map((stage) => ({
                ...stage,
                cards: rotations.filter(
                  (rotation) => rotation.currentStageOrder === stage.order
                ),
              })),
            };
          }
        }
        // console.log(lifecyclesMap);
        setLifecycles(lifecyclesMap);

        setLocations(fetchedLocations);
        setSelectedLocation(fetchedLocations[0]);
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchLocations();
    }
  }, [user?.id, permissions?.id]);

  return (
    <LocationContext.Provider
      value={{
        locations,
        setLocations,
        selectedLocation,
        setSelectedLocation,
        baseId,
        setBaseId,
        loading,
        lifecycles,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
