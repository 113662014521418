import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPermissions, updatePermissions } from '../services/firebaseService';
import {
  ArrowLeftIcon,
  CheckIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";

const AdminManagePermissions = () => {
  const navigate = useNavigate();
  const [studentPermissions, setStudentPermissions] = useState({});
  const [institutePermissions, setInstitutePermissions] = useState({});
  const [trainerPermissions, setTrainerPermissions] = useState({});
  const [adminPermissions, setAdminPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('student');
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true);
      try {
        const studentData = await getPermissions('STUDENT');
        const instituteData = await getPermissions('INSTITUTION');
        const trainerData = await getPermissions('TRAINER');
        const adminData = await getPermissions('ADMIN');

        if (studentData) setStudentPermissions(studentData);
        if (instituteData) setInstitutePermissions(instituteData);
        if (trainerData) setTrainerPermissions(trainerData);
        if (adminData) setAdminPermissions(adminData);
      } catch (error) {
        setErrorMessage("Error fetching permissions: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const handleTogglePermission = (permissionKey, type) => {
    switch(type) {
      case 'student':
        setStudentPermissions(prev => ({
          ...prev,
          [permissionKey]: !prev[permissionKey],
        }));
        break;
      case 'institute':
        setInstitutePermissions(prev => ({
          ...prev,
          [permissionKey]: !prev[permissionKey],
        }));
        break;
      case 'trainer':
        setTrainerPermissions(prev => ({
          ...prev,
          [permissionKey]: !prev[permissionKey],
        }));
        break;
      case 'admin':
        setAdminPermissions(prev => ({
          ...prev,
          [permissionKey]: !prev[permissionKey],
        }));
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      await updatePermissions('STUDENT', studentPermissions);
      await updatePermissions('INSTITUTION', institutePermissions);
      await updatePermissions('TRAINER', trainerPermissions);
      await updatePermissions('ADMIN', adminPermissions);
      setSuccessMessage("Permissions updated successfully!");
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      setErrorMessage("Error updating permissions: " + error.message);
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <>
      <div className="mb-4">
        <button
          onClick={() => window.history.back()}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          <ArrowLeftIcon className="h-5 w-5 inline-block mr-2" />
          Back
        </button>
      </div>

      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Manage Permissions
          </h1>

          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}

          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}

          <div className="bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex border-b border-gray-200 mb-6">
              <button
                className={`py-2 px-4 mr-4 ${activeTab === 'student' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => setActiveTab('student')}
              >
                Student
              </button>
              <button
                className={`py-2 px-4 mr-4 ${activeTab === 'institute' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => setActiveTab('institute')}
              >
                Institute
              </button>
              <button
                className={`py-2 px-4 mr-4 ${activeTab === 'trainer' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => setActiveTab('trainer')}
              >
                Trainer
              </button>
              <button
                className={`py-2 px-4 ${activeTab === 'admin' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => setActiveTab('admin')}
              >
                Admin
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {activeTab === 'student' && (
                Object.keys(studentPermissions).map((key) => (
                  <div key={key} className="flex items-center justify-between p-4 border rounded">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={studentPermissions[key]}
                        onChange={() => handleTogglePermission(key, 'student')}
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{key}</span>
                    </label>
                    {studentPermissions[key] ? (
                      <CheckIcon className="h-5 w-5 text-green-500" />
                    ) : (
                      <XMarkIcon className="h-5 w-5 text-red-500" />
                    )}
                  </div>
                ))
              )}
              {activeTab === 'institute' && (
                Object.keys(institutePermissions).map((key) => (
                  <div key={key} className="flex items-center justify-between p-4 border rounded">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={institutePermissions[key]}
                        onChange={() => handleTogglePermission(key, 'institute')}
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{key}</span>
                    </label>
                    {institutePermissions[key] ? (
                      <CheckIcon className="h-5 w-5 text-green-500" />
                    ) : (
                      <XMarkIcon className="h-5 w-5 text-red-500" />
                    )}
                  </div>
                ))
              )}
              {activeTab === 'trainer' && (
                Object.keys(trainerPermissions).map((key) => (
                  <div key={key} className="flex items-center justify-between p-4 border rounded">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={trainerPermissions[key]}
                        onChange={() => handleTogglePermission(key, 'trainer')}
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{key}</span>
                    </label>
                    {trainerPermissions[key] ? (
                      <CheckIcon className="h-5 w-5 text-green-500" />
                    ) : (
                      <XMarkIcon className="h-5 w-5 text-red-500" />
                    )}
                  </div>
                ))
              )}
              {activeTab === 'admin' && (
                Object.keys(adminPermissions).map((key) => (
                  <div key={key} className="flex items-center justify-between p-4 border rounded">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={adminPermissions[key]}
                        onChange={() => handleTogglePermission(key, 'admin')}
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{key}</span>
                    </label>
                    {adminPermissions[key] ? (
                      <CheckIcon className="h-5 w-5 text-green-500" />
                    ) : (
                      <XMarkIcon className="h-5 w-5 text-red-500" />
                    )}
                  </div>
                ))
              )}
            </div>

            <button
              onClick={() => setIsSaveModalOpen(true)}
              className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>

      {isSaveModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Save Changes</h2>
            <p className="mb-4">Are you sure you want to save these changes?</p>
            <div className="flex justify-end">
              <button
                onClick={() => setIsSaveModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleSubmit();
                  setIsSaveModalOpen(false);
                }}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminManagePermissions;
