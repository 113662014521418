import React, { useEffect } from "react";
const Payment = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://secure.clickpay.com.sa/payment/js/paylib.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const payform = document.getElementById("payform");
      window.paylib.inlineForm({
        key: process.env.REACT_APP_CLICKPAY_CLIENT_KEY,
        form: payform,
        autoSubmit: false,
        callback: function (response) {
          const errorElement = document.getElementById("paymentErrors");
          errorElement.innerHTML = "";
          if (response.error) {
            window.paylib.handleError(errorElement, response);
          } else if (response.token) {
            handlePaymentToken(response.token);
          }
        },
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePaymentToken = async (token) => {
    try {
      const paymentData = {
        cartId: crypto.randomUUID(),
        cartDescription: "Payment for services",
        cartCurrency: "SAR",
        cartAmount: 100.0,
        customerDetails: {
          name: "John Smith",
          email: "customer@example.com",
          street1: "123 Street",
          city: "Dubai",
          state: "DU",
          country: "AE",
          ip: "94.204.129.89",
        },
        paymentToken: token,
      };

      const response = await fetch(
        "http://localhost:3001/api/process-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const result = await response.json();

      if (result.redirect_url) {
        window.location.href = result.redirect_url;
      } else if (result.payment_result) {
        if (result.payment_result.response_status === "A") {
          alert("Payment successful!");
        } else {
          alert("Payment failed: " + result.payment_result.response_message);
        }
      }
    } catch (error) {
      console.error("Payment error:", error);
      alert("Payment processing failed. Please try again.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
      <form id="payform" onSubmit={handleSubmit} className="space-y-4">
        <div id="paymentErrors" className="text-red-500"></div>

        <div className="space-y-2">
          <label className="block text-gray-700">Card Number</label>
          <input
            type="text"
            data-paylib="number"
            className="w-full p-2 border rounded"
            size="20"
          />
        </div>

        <div className="flex space-x-4">
          <div className="space-y-2">
            <label className="block text-gray-700">Expiry Month</label>
            <input
              type="text"
              data-paylib="expmonth"
              className="w-20 p-2 border rounded"
              size="2"
              placeholder="MM"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-gray-700">Expiry Year</label>
            <input
              type="text"
              data-paylib="expyear"
              className="w-24 p-2 border rounded"
              size="4"
              placeholder="YYYY"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-gray-700">Security Code</label>
          <input
            type="text"
            data-paylib="cvv"
            className="w-24 p-2 border rounded"
            size="4"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Pay Now
        </button>
      </form>
    </div>
  );
};
export default Payment;
