// src/pages/LandingPage.jsx

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addContactUser, countVisitors } from "../services/firebaseService"; // Adjust the path as needed
import logo from "./rota.png";
import bg from "./bg.jpeg";
import "./LandingPageWithOpps.css";
import Map from "../components/Map/Map";
import FilterBadge from "../components/FilterBadge/FilterBadge";
import { useRotations } from "../hooks/useRotations";
import { UserContext } from "../UserContext";
import {
  getOpportunities,
  getUserRegistrations,
} from "../services/firebaseService";
import { signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher/LanguageSwitcher";
import { auth } from "../configuration";
import { logUtmParameters } from "../configuration";

const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-full p-8">
    <div className="animate-spin rounded-full h-16 w-16 border-4 border-gray-200 border-t-[#0e9065]"></div>
    <p className="mt-4 text-gray-500">Loading opportunities...</p>
  </div>
);

const Section = ({ imageSrc, title, description }) => (
  <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12">
    <div className="w-full md:w-1/2">
      <div className="aspect-square rounded-full overflow-hidden">
        <img
          src={imageSrc}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
    </div>
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useContext(UserContext);
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilters, setActiveFilters] = useState({
    trainingCategory: null,
    major: null,
    paidFree: null,
  });

  const location = useLocation();

  useEffect(() => {
    const fetchOpportunities = async () => {
      setLoading(true);
      try {
        const allOpportunities = await getOpportunities();
        if (user) {
          const userRegistrations = await getUserRegistrations(user.id);
          const filteredOpportunities = allOpportunities.filter(
            (opp) => !userRegistrations.includes(opp.id)
          );
          setOpportunities(filteredOpportunities);
        } else {
          setOpportunities(allOpportunities);
        }
      } catch (err) {
        console.error("Error fetching opportunities:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, [user?.id]);

  useEffect(() => {
    const params = window.location.search;
    console.log("URL Params:", params); // Debug log

    // Parse parameters
    const searchParams = new URLSearchParams(params);

    // Check all possible tracking parameters
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmCampaign = searchParams.get("utm_campaign");
    console.log("UTM Source:", utmSource); // Debug log

    // Log UTM parameters using the imported function
    logUtmParameters(utmSource, utmMedium, utmCampaign);

    countVisitors(utmSource);
  }, []);

  const filteredOpportunities = opportunities.filter((opportunity) => {
    const searchString = searchTerm.toLowerCase();
    const matchesSearch =
      (
        (i18n.language === "ar"
          ? opportunity?.location?.name_ar || opportunity?.location?.name
          : opportunity?.location?.name
        )?.toLowerCase() || ""
      ).includes(searchString) ||
      (
        (i18n.language === "ar"
          ? opportunity?.name_ar || opportunity?.name
          : opportunity?.name
        )?.toLowerCase() || ""
      ).includes(searchString) ||
      (
        (i18n.language === "ar"
          ? opportunity?.description_ar || opportunity?.description_en
          : opportunity?.description_en
        )?.toLowerCase() || ""
      ).includes(searchString);

    const matchesTrainingCategory =
      !activeFilters.trainingCategory ||
      opportunity?.category === activeFilters.trainingCategory;

    const matchesMajor =
      !activeFilters.major ||
      (i18n.language === "ar"
        ? opportunity?.major_ar === activeFilters.major
        : opportunity?.major_en === activeFilters.major);

    const matchesPaidFree =
      !activeFilters.paidFree ||
      (activeFilters.paidFree === "Free"
        ? opportunity?.price === "Unpaid"
        : typeof opportunity?.price === "number");

    return (
      matchesSearch &&
      matchesTrainingCategory &&
      matchesMajor &&
      matchesPaidFree
    );
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addContactUser(formData);
      setSuccessMessage("Your information has been successfully submitted!");
      setFormData({ name: "", email: "", phone: "" });
      setOpenModal(false);
      setIsSuccess(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const rightArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 18l6-6-6-6" />
    </svg>
  );

  const rotaButton = (text, subText, onClick) => (
    <button
      className="border-2 border-[#0e9065] hover:pr-1 text-[#0e9065] py-1 px-6 text-left flex justify-between items-center h-14 text-sm lg:text-base"
      onClick={onClick || (() => {})}
    >
      <span
        className="flex flex-col leading-tight"
        style={{ letterSpacing: "0.1em" }}
      >
        <span>{text}</span>
        {subText && <span className="hidden lg:block text-sm">{subText}</span>}
      </span>
      <span className="text-[#0e9065] ml-2 text-xl font-bold">
        {rightArrow}
      </span>
    </button>
  );

  return (
    <div>
      <div className="fixed top-4 right-4 z-50 opacity-75 hover:opacity-100 transition-opacity">
        <LanguageSwitcher />
      </div>

      <header
        className="bg-cover bg-center h-screen flex flex-col items-center justify-center text-white"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img
          src={logo}
          alt="ROTA Logo"
          className="w-[26rem] max-w-[90%] h-auto mb-6"
        />
        <p className="text-lg md:text-xl text-center">
          {t("landing.hero.tagline")}
        </p>
      </header>

      <div className="py-12 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-4">
            <input
              type="text"
              placeholder={t("landing.search.placeholder")}
              className="w-full p-3 rounded-lg border border-gray-200 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="mb-6 space-y-4">
            <div className="flex flex-wrap gap-2">
              <span className="text-sm text-gray-500 mr-2">
                {t("landing.search.filters.trainingCategory")}
              </span>
              <FilterBadge
                label={t("landing.search.filters.categories.undergradStudent")}
                isActive={
                  activeFilters.trainingCategory === "Undergrad Student"
                }
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    trainingCategory:
                      prev.trainingCategory === "Undergrad Student"
                        ? null
                        : "Undergrad Student",
                  }))
                }
              />
              <FilterBadge
                label={t(
                  "landing.search.filters.categories.postgradPractitioner"
                )}
                isActive={
                  activeFilters.trainingCategory === "Postgrad Practitioner"
                }
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    trainingCategory:
                      prev.trainingCategory === "Postgrad Practitioner"
                        ? null
                        : "Postgrad Practitioner",
                  }))
                }
              />
              <FilterBadge
                label={t("landing.search.filters.categories.internshipPeriod")}
                isActive={
                  activeFilters.trainingCategory === "Internship Period"
                }
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    trainingCategory:
                      prev.trainingCategory === "Internship Period"
                        ? null
                        : "Internship Period",
                  }))
                }
              />
            </div>

            <div className="flex flex-wrap gap-2">
              <span className="text-sm text-gray-500 mr-2">
                {t("landing.search.filters.major")}
              </span>
              {[
                t("landing.search.filters.Majors.medicine"),
                t("landing.search.filters.Majors.dentistry"),
                t("landing.search.filters.Majors.pharmacy"),
                t("landing.search.filters.Majors.nursing"),
                t("landing.search.filters.Majors.physiotherapy"),
                t("landing.search.filters.Majors.laboratory"),
                t("landing.search.filters.Majors.radiology"),
              ].map((major) => (
                <FilterBadge
                  key={major}
                  label={major}
                  isActive={activeFilters.major === major}
                  onClick={() =>
                    setActiveFilters((prev) => ({
                      ...prev,
                      major: prev.major === major ? null : major,
                    }))
                  }
                />
              ))}
            </div>

            <div className="flex flex-wrap gap-2">
              <span className="text-sm text-gray-500 mr-2">
                {t("landing.search.filters.payment")}
              </span>
              <FilterBadge
                label={t("landing.search.filters.paid")}
                isActive={activeFilters.paidFree === "Paid"}
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    paidFree: prev.paidFree === "Paid" ? null : "Paid",
                  }))
                }
              />
              <FilterBadge
                label={t("landing.search.filters.free")}
                isActive={activeFilters.paidFree === "Free"}
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    paidFree: prev.paidFree === "Free" ? null : "Free",
                  }))
                }
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-4">
            <div className="relative rounded-lg overflow-hidden shadow-lg h-[300px] lg:h-[calc(100vh-12rem)] z-0">
              <Map
                initialZoom={10}
                markers={filteredOpportunities
                  .filter(
                    (opportunity) =>
                      opportunity.location.location &&
                      opportunity.location.location._lat !== undefined &&
                      opportunity.location.location._long !== undefined &&
                      opportunity?.seats > 0
                  )
                  .map((opportunity) => {
                    return {
                      position: [
                        opportunity.location.location._lat,
                        opportunity.location.location._long,
                      ],
                      popupContent: (
                        <div>
                          <h1 className="text-2xl font-bold">
                            {opportunity.location.name}
                          </h1>
                          <p>{opportunity.name}</p>
                          <p>{opportunity.description}</p>
                        </div>
                      ),
                    };
                  })}
                mapStyle={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "0.5rem",
                }}
              />
            </div>

            <div className="bg-white rounded-lg shadow-lg p-4">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t("landing.search.opportunities.title")}
              </h2>

              <div className="lg:h-[calc(100vh-16rem)] lg:overflow-y-auto">
                {loading ? (
                  <LoadingSpinner />
                ) : filteredOpportunities.filter((opp) => opp?.seats > 0)
                    .length === 0 ? (
                  <div className="flex items-center justify-center h-full">
                    <p className="text-gray-500 text-center">
                      {t("landing.search.opportunities.noResults")}
                    </p>
                  </div>
                ) : (
                  <div className="grid gap-4">
                    {filteredOpportunities
                      .filter((opportunity) => opportunity?.seats > 0)
                      .map((opportunity) => (
                        <div
                          key={opportunity.id}
                          onClick={async () => {
                            if (user) {
                              await signOut(auth);
                            }
                            navigate(`/app/search/${opportunity.id}`);
                          }}
                          className="bg-gray-50 rounded-lg p-4 hover:shadow-md transition-shadow cursor-pointer border border-gray-100"
                        >
                          <div className="flex gap-4">
                            <img
                              src={opportunity.location.image}
                              alt={opportunity.location.name}
                              className="w-24 h-24 object-cover rounded-lg"
                            />
                            <div className="flex-1">
                              <h3 className="font-semibold text-gray-800">
                                {i18n.language === "ar"
                                  ? opportunity.location.name_ar ||
                                    opportunity.location.name
                                  : opportunity.location.name}
                              </h3>
                              <p className="text-sm text-gray-600 mt-1">
                                {i18n.language === "ar"
                                  ? opportunity.name_ar || opportunity.name
                                  : opportunity.name}
                              </p>
                              <p className="text-sm text-gray-500 mt-2 line-clamp-2">
                                {i18n.language === "ar"
                                  ? opportunity.description_ar ||
                                    opportunity.description_en
                                  : opportunity.description_en}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-12 bg-[#070c29]" id="how-to">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:w-[80%] mb-12" style={{ letterSpacing: "0.1em" }}>
            <h2 className="text-3xl font-bold mb-2 text-[#0e9065]">
              {t("landing.howTo.title")}
            </h2>
            <p className="text-white text-sm">{t("landing.howTo.subtitle")}</p>
          </div>
        </div>

        <div className="py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-6 relative">
              <div
                className="hidden md:block absolute top-[2.5rem] left-[10%] right-[10%] border-t-2 border-dashed border-white"
                style={{ zIndex: 0 }}
              />

              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.createAccount")}
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.explore")}
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 11H15M9 15H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.book")}
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.pay")}
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.prepare")}
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.begin")}
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 7V3m0 0L8 7m4-4l4 4m-4 4v10M4 15l8 8 8-8"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  {t("landing.howTo.steps.complete")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#15b6be] py-16" id="contact">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col-reverse md:flex-row justify-between items-center gap-12">
            <div
              className="flex flex-col items-center md:items-start"
              dir="ltr"
            >
              <img src={logo} alt="ROTA Logo" className="w-48 h-auto mb-8" />
              <div className="flex flex-col space-y-4 text-white">
                <div className="flex items-center space-x-3">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <a href="mailto:hi@ehealth.sa" className="hover:underline">
                    hi@ehealth.sa
                  </a>
                </div>
                <div className="flex items-center space-x-3">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <a href="tel:+966-56-628-8666" className="hover:underline">
                    +966 56 628 8666
                  </a>
                </div>
              </div>

              <div className="flex space-x-6 mt-8">
                <a
                  href="https://x.com/ehealthksa"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-gray-200"
                >
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/ehealthksa/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-gray-200"
                >
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="mt-12 flex justify-center">
              <button
                className="flex justify-between items-center w-[400px] bg-transparent border-2 text-left border-white text-white font-bold py-4 px-8 text-xl shadow-lg transform transition-transform hover:scale-105"
                onClick={() => setOpenModal(true)}
              >
                <div className="flex items-center">
                  <div
                    className="flex flex-col underline"
                    style={{ letterSpacing: "0.1em" }}
                  >
                    <p>{t("landing.contact.applyButton")}</p>
                    <p>{t("landing.contact.waitingList")}</p>
                  </div>
                </div>
                <svg
                  className="h-6 w-6 ml-2 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </button>
            </div>

            {/* <div className="w-full md:w-1/2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.674754711687!2d46.6752!3d24.7136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDQyJzQ5LjAiTiA0NsKwNDAnMzAuNyJF!5e0!3m2!1sen!2ssa!4v1629291234567!5m2!1sen!2ssa"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                className="rounded-lg shadow-lg"
              ></iframe>
            </div> */}
          </div>
          {openModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-[#070c29] p-8 rounded-lg max-w-md w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl text-white">
                    {t("landing.contact.form.title")}
                  </h2>
                  <button
                    onClick={() => setOpenModal(false)}
                    className="text-white hover:text-gray-300"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder={t("landing.contact.form.name")}
                    value={formData.name}
                    onChange={handleChange}
                    className="block w-full p-2 mb-4 border border-gray-300"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder={t("landing.contact.form.email")}
                    value={formData.email}
                    onChange={handleChange}
                    className={`block w-full p-2 mb-4 border border-gray-300 ${
                      i18n.language === "ar"
                        ? "placeholder:text-right"
                        : "placeholder:text-left"
                    }`}
                    required
                    dir="ltr"
                  />
                  <input
                    type="tel"
                    name="phone"
                    placeholder={t("landing.contact.form.phone")}
                    value={formData.phone}
                    onChange={handleChange}
                    className={`block w-full p-2 mb-4 border border-gray-300 ${
                      i18n.language === "ar"
                        ? "placeholder:text-right"
                        : "placeholder:text-left"
                    }`}
                    required
                    dir="ltr"
                  />
                  <button
                    type="submit"
                    className="w-full p-2 bg-[#0e9065] text-white hover:bg-[#0b7a55]"
                  >
                    {t("landing.contact.form.submit")}
                  </button>
                </form>
                {successMessage && (
                  <p className="text-center text-green-500 mt-4">
                    {t("landing.contact.form.success")}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#0e9065]"></div>
        </div>
      )}

      {isSuccess && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
            <div className="flex items-center justify-center mb-4">
              <svg
                className="w-16 h-16 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold text-center mb-2">Success!</h3>
            <p className="text-gray-600 text-center mb-6">{successMessage}</p>
            <button
              onClick={() => setIsSuccess(false)}
              className="w-full p-2 bg-[#0e9065] text-white rounded hover:bg-[#0b7a55]"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
