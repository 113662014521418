import { useState, useEffect, useContext } from "react";
import Map from "../Map/Map";
import { useRotations } from "../../hooks/useRotations";
import { useParams, useNavigate } from "react-router-dom";
import RequestForm from "../RequestForm/RequestForm";
import { UserContext } from "../../UserContext";
import {
  createCard,
  getLocationSectionsByLocationIdAndOrder,
  getOpportunities,
  createRotationRequest,
  checkRotationExists,
  getUserRegistrations,
  getUserRegisteredOpportunities,
} from "../../services/firebaseService";
import { format, isWithinInterval, parseISO } from "date-fns";
import Modal from "../Modal/Modal";
import SignupPage from "../../pages/Auth/SignupPage";
import Contact from "../contact/Contact";
import FilterBadge from "../FilterBadge/FilterBadge";
import { useTranslation } from "react-i18next";

import uni1 from "../../assets/images/uni1.png";
import uni2 from "../../assets/images/uni2.png";
import placeholder from "../../assets/images/placeholder.png";
const Search = () => {
  const { t, i18n } = useTranslation();
  const { opportunityId } = useParams();
  const { user, permissions } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateConflict, setDateConflict] = useState(null);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    trainingCategory: null,
    major: null,
    paidFree: null,
    city: null,
  });
  const [citiesName, setCitiesName] = useState([]);

  const DateDisplay = ({ startDate, endDate }) => {
    const dateClass = "text-sm text-gray-600 dark:text-gray-300";

    return (
      <>
        <p className={dateClass}>{startDate}</p>
        {endDate && <p className={dateClass}>{endDate}</p>}
      </>
    );
  };

  const OpportunityPopup = ({ opportunity }) => (
    <div className="flex">
      <img
        src={opportunity.location.image || placeholder}
        alt="University Logo"
        className="mr-4"
        style={{ width: "90px", height: "90px", objectFit: "contain" }}
      />
      <div>
        <h1 className="text-2xl font-bold">{opportunity.location.name}</h1>
        <p>{opportunity.name}</p>
        <p className="line-clamp-2">{opportunity.description}</p>
        <p>{opportunity.startDate}</p>
        {opportunity.endDate && <p>{opportunity.endDate}</p>}
        <button
          className="mt-2 px-4 py-2 rounded"
          style={{ backgroundColor: "#0070f3", color: "white" }}
          onClick={() => {
            setSelectedOpportunity(opportunity);
            setIsModalOpen(true);
          }}
        >
          View Details
        </button>
      </div>
    </div>
  );

  const getCityName = async (lat, lon) => {
    try {
      // Check if city already exists
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      const city = data.address.city || 
                   data.address.town || 
                   data.address.village || 
                   data.address.suburb ||
                   data.address.county;
                   
      if (!citiesName.includes(city)) {
        setCitiesName([...citiesName, city]);
        return city;
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
    }
  };

  useEffect(() => {
    // console.log("user:", user);
    const fetchOpportunities = async () => {
      setLoading(true);
      try {
        // Get all opportunities in one call
        const allOpportunities = await getOpportunities();
        // console.log("allOpportunities", allOpportunities);


        if (user) {
          // Get all user registrations in one call
          const userRegistrations = await getUserRegistrations(user.id);
          // Filter locally using the registration data
          const filteredOpportunities = allOpportunities.filter(
            (opp) => !userRegistrations.includes(opp.id)
          );
          setOpportunities(filteredOpportunities);

          // If opportunityId exists in params, find and select it
          if (opportunityId) {
            const opportunity = filteredOpportunities.find(
              (opp) => opp.id === opportunityId
            );
            if (opportunity) {
              setSelectedOpportunity(opportunity);
              setIsModalOpen(true);
            }
          }
        } else {
          setOpportunities(allOpportunities);

          // If opportunityId exists in params, find and select it
          if (opportunityId) {
            const opportunity = allOpportunities.find(
              (opp) => opp.id === opportunityId
            );
            if (opportunity) {
              setSelectedOpportunity(opportunity);
              setIsModalOpen(true);
            }
          }
        }
      } catch (err) {
        console.error("Error fetching opportunities:", err);
        setError("Failed to load opportunities");
      } finally {
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, [user?.id, opportunityId]);

  const checkDateConflicts = async (selectedOpp) => {
    try {
      const userRegistrations = await getUserRegisteredOpportunities(user.id);
      const registeredOpps = userRegistrations.map((reg) => {
        return opportunities.find((opp) => opp.id === reg.id) || reg;
      });

      // Safely convert Firestore timestamp to milliseconds
      const getTimeInMs = (date) => {
        if (!date) return null;
        // Handle Firestore Timestamp
        if (date.seconds) return date.seconds * 1000;
        // Handle Date object
        if (date instanceof Date) return date.getTime();
        // Handle ISO string
        if (typeof date === "string") return new Date(date).getTime();
        return null;
      };

      const selectedStartTime = getTimeInMs(selectedOpp.startDate);
      const selectedEndTime =
        getTimeInMs(selectedOpp.endDate) || selectedStartTime;

      if (!selectedStartTime) {
        throw new Error("Invalid start date");
      }

      // console.log("Selected opportunity dates:", {
      //   start: new Date(selectedStartTime).toISOString(),
      //   end: new Date(selectedEndTime).toISOString(),
      // });

      const conflicts = registeredOpps.filter((regOpp) => {
        const regStartTime = getTimeInMs(regOpp.startDate);
        const regEndTime = getTimeInMs(regOpp.endDate) || regStartTime;

        if (!regStartTime) return false;

        // Check for any overlap between date ranges
        const hasConflict =
          // New rotation starts during existing rotation
          (selectedStartTime >= regStartTime &&
            selectedStartTime < regEndTime) ||
          // New rotation ends during existing rotation
          (selectedEndTime > regStartTime && selectedEndTime <= regEndTime) ||
          // New rotation completely encompasses existing rotation
          (selectedStartTime <= regStartTime && selectedEndTime >= regEndTime);

        if (hasConflict) {
          // console.log("Conflict detected with:", regOpp.name);
        }
        // console.log("hasConflict", hasConflict);
        return hasConflict;
      });

      // console.log("Total conflicts found:", conflicts.length);
      // console.log("conflicts", conflicts);
      return conflicts;
    } catch (error) {
      console.error("Error checking date conflicts:", error);
      throw new Error("Failed to check date conflicts");
    }
  };

  const handleApply = async () => {
    if (!user) {
      setIsSignupModalOpen(true);
      return;
    }
    try {
      const conflicts = await checkDateConflicts(selectedOpportunity);

      if (conflicts.length > 0) {
        const conflictDetails = conflicts.map((conflict) => ({
          name: conflict.name || "Unknown Rotation",
          dates: `${new Date(conflict.startDate.seconds * 1000).toLocaleString(
            "en-US",
            {
              month: "short",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }
          )}${
            conflict.endDate
              ? ` to ${new Date(conflict.endDate.seconds * 1000).toLocaleString(
                  "en-US",
                  {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  }
                )}`
              : ""
          }`,
        }));

        setDateConflict(conflictDetails);
        return;
      }

      setIsRequestFormOpen(true);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error in handleApply:", error);
      setError("Failed to process application. Please try again.");
    }
  };

  const handleFormSubmit = async (submissionId) => {
    setIsRequestFormOpen(false);
    setSuccess(true);
  };

  const filteredOpportunities = opportunities.filter((opportunity) => {
    const searchString = searchTerm.toLowerCase();
    const matchesSearch =
      (
        (i18n.language === "ar"
          ? opportunity?.location?.name_ar || opportunity?.location?.name
          : opportunity?.location?.name
        )?.toLowerCase() || ""
      ).includes(searchString) ||
      (
        (i18n.language === "ar"
          ? opportunity?.name_ar || opportunity?.name
          : opportunity?.name
        )?.toLowerCase() || ""
      ).includes(searchString) ||
      (
        (i18n.language === "ar"
          ? opportunity?.description_ar || opportunity?.description_en
          : opportunity?.description_en
        )?.toLowerCase() || ""
      ).includes(searchString);

    const matchesTrainingCategory =
      !activeFilters.trainingCategory ||
      opportunity?.category === activeFilters.trainingCategory;

    const matchesMajor =
      !activeFilters.major ||
      (i18n.language === "ar"
        ? opportunity?.major_ar === activeFilters.major
        : opportunity?.major_en === activeFilters.major);

    const matchesPaidFree =
      !activeFilters.paidFree ||
      (activeFilters.paidFree === "Free"
        ? opportunity?.price === "Unpaid"
        : typeof opportunity?.price === "number");

    return (
      matchesSearch &&
      matchesTrainingCategory &&
      matchesMajor &&
      matchesPaidFree
    );
  });

  // Add translations for modal text
  const modalTranslations = {
    close: t("landing.search.modal.close"),
    apply: t("landing.search.modal.apply"),
    payAndApply: t("landing.search.modal.payAndApply"),
    schedule: t("landing.search.modal.schedule"),
    description: t("landing.search.modal.description"),
    paymentDetails: t("landing.search.modal.paymentDetails"),
    trainingCharge: t("landing.search.modal.trainingCharge"),
    serviceFee: t("landing.search.modal.serviceFee"),
    totalAmount: t("landing.search.modal.totalAmount"),
    availableSeats: t("landing.search.modal.availableSeats"),
    posted: t("landing.search.modal.posted"),
    start: t("landing.search.modal.start"),
    end: t("landing.search.modal.end"),
    major: t("landing.search.modal.major"),
    duration: t("landing.search.modal.duration"),
  };

  // Update modal content with translations
  const modalContent = selectedOpportunity ? (
    <div className="p-6 overflow-y-auto max-h-[calc(80vh-4rem)]">
      <div className="flex flex-col sm:flex-row gap-6">
        <div className="w-32 h-32 rounded-lg shadow-md bg-white dark:bg-white p-2 sm:mx-0 mx-auto">
          <img
            src={selectedOpportunity.location.image || placeholder}
            alt="University Logo"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="flex-1 space-y-4">
          <div className="sm:text-left text-center">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              {i18n.language === "ar"
                ? selectedOpportunity.location.name_ar ||
                  selectedOpportunity.location.name
                : selectedOpportunity.location.name}
            </h2>
            <h3 className="text-xl text-blue-600 dark:text-blue-400 font-medium mt-1">
              {i18n.language === "ar"
                ? selectedOpportunity.name_ar || selectedOpportunity.name
                : selectedOpportunity.name}
            </h3>
          </div>

          {/* Major and Duration Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                {modalTranslations.major}
              </h4>
              <p className="text-gray-800 dark:text-gray-200">
                {i18n.language === "ar"
                  ? selectedOpportunity.major_ar || selectedOpportunity.major_en
                  : selectedOpportunity.major_en}
              </p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                {modalTranslations.duration}
              </h4>
              <p className="text-gray-800 dark:text-gray-200">
                {i18n.language === "ar"
                  ? selectedOpportunity.duration_ar ||
                    selectedOpportunity.duration_en
                  : selectedOpportunity.duration_en}
              </p>
            </div>
          </div>

          {/* Dates Section */}
          <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
              {modalTranslations.schedule}
            </h4>
            <div className="space-y-1">
              <p className="text-gray-800 dark:text-gray-200">
                {modalTranslations.start}:{" "}
                {selectedOpportunity.startDate
                  ? new Date(selectedOpportunity.startDate).toLocaleString(
                      i18n.language === "ar" ? "ar-SA" : "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      }
                    )
                  : "Date not specified"}
              </p>
              {selectedOpportunity.endDate && (
                <p className="text-gray-800 dark:text-gray-200">
                  {modalTranslations.end}:{" "}
                  {new Date(selectedOpportunity.endDate).toLocaleString(
                    i18n.language === "ar" ? "ar-SA" : "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    }
                  )}
                </p>
              )}
            </div>
          </div>

          {/* Description */}
          <div>
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
              {modalTranslations.description}
            </h4>
            <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
              {i18n.language === "ar"
                ? selectedOpportunity.description_ar ||
                  selectedOpportunity.description_en
                : selectedOpportunity.description_en ||
                  selectedOpportunity.description}
            </p>
          </div>

          {/* Payment Details Section */}
          <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-3">
              {modalTranslations.paymentDetails}
            </h4>
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-gray-600 dark:text-gray-300">
                  {modalTranslations.trainingCharge}
                </span>
                <span className="font-medium text-gray-800 dark:text-gray-200">
                  {selectedOpportunity.price === "Unpaid"
                    ? t("landing.search.filters.categories.free")
                    : `${selectedOpportunity.price} ${
                        i18n.language === "ar" ? "ريال" : "SAR"
                      }`}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600 dark:text-gray-300">
                  {modalTranslations.serviceFee}
                </span>
                <span className="font-medium text-gray-800 dark:text-gray-200">
                  {selectedOpportunity.fee
                    ? `${selectedOpportunity.fee} ${
                        i18n.language === "ar" ? "ريال" : "SAR"
                      }`
                    : "0 SAR"}
                </span>
              </div>
              <div className="border-t border-gray-200 dark:border-gray-600 pt-2 mt-2">
                <div className="flex justify-between items-center">
                  <span className="font-medium text-gray-700 dark:text-gray-200">
                    {modalTranslations.totalAmount}
                  </span>
                  <span className="font-bold text-lg text-blue-600 dark:text-blue-400">
                    {selectedOpportunity.price === "Unpaid"
                      ? `${selectedOpportunity.fee || "0"} ${
                          i18n.language === "ar" ? "ريال" : "SAR"
                        }`
                      : `${
                          selectedOpportunity.price + selectedOpportunity.fee
                        } ${i18n.language === "ar" ? "ريال" : "SAR"}`}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Info */}
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <span className="text-gray-500 dark:text-gray-400">
                {modalTranslations.availableSeats}
              </span>
              <span className="m-2 text-gray-800 dark:text-gray-200">
                {selectedOpportunity.seats}
              </span>
            </div>
            <div>
              <span className="text-gray-500 dark:text-gray-400">
                {modalTranslations.posted}
              </span>
              <span className="m-2 text-gray-800 dark:text-gray-200">
                {new Date(
                  selectedOpportunity.createdAt.seconds * 1000
                ).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  // Update button text
  const applyButtonText =
    user && permissions?.id === "STUDENT"
      ? modalTranslations.apply
      : modalTranslations.payAndApply;

  return (
    <div
      className={`min-h-screen bg-gray-50 dark:bg-gray-900 p-4 ${
        i18n.language === "ar" ? "rtl" : "ltr"
      }`}
    >
      {/* Loading Spinner */}
      {loading && (
        <div className="fixed inset-0 bg-white/75 dark:bg-gray-900/75 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
        </div>
      )}

      {/* Error Modal */}
      {error && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Error
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => setError(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="max-w-8xl mx-auto">
        {/* Search Bar */}
        <div className="mb-4">
          <input
            type="text"
            placeholder={t("landing.search.placeholder")}
            className="w-full p-3 rounded-lg border border-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:text-white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="mb-6 space-y-4">
          {/* Training Category Filters */}
          <div className="flex flex-wrap gap-2">
            <span className="text-sm text-gray-500 mr-2">
              {t("landing.search.filters.trainingCategory")}
            </span>
            <FilterBadge
              label={t("landing.search.filters.categories.undergradStudent")}
              isActive={activeFilters.trainingCategory === "Undergrad Student"}
              onClick={() =>
                setActiveFilters((prev) => ({
                  ...prev,
                  trainingCategory:
                    prev.trainingCategory === "Undergrad Student"
                      ? null
                      : "Undergrad Student",
                }))
              }
            />
            <FilterBadge
              label={t(
                "landing.search.filters.categories.postgradPractitioner"
              )}
              isActive={
                activeFilters.trainingCategory === "Postgrad Practitioner"
              }
              onClick={() =>
                setActiveFilters((prev) => ({
                  ...prev,
                  trainingCategory:
                    prev.trainingCategory === "Postgrad Practitioner"
                      ? null
                      : "Postgrad Practitioner",
                }))
              }
            />
            <FilterBadge
              label={t("landing.search.filters.categories.internshipPeriod")}
              isActive={activeFilters.trainingCategory === "Internship Period"}
              onClick={() =>
                setActiveFilters((prev) => ({
                  ...prev,
                  trainingCategory:
                    prev.trainingCategory === "Internship Period"
                      ? null
                      : "Internship Period",
                }))
              }
            />
          </div>

          {/* Major Filters */}
          <div className="flex flex-wrap gap-2">
            <span className="text-sm text-gray-500 mr-2">
              {t("landing.search.filters.major")}
            </span>
            {[
              t("landing.search.filters.Majors.medicine"),
              t("landing.search.filters.Majors.dentistry"),
              t("landing.search.filters.Majors.pharmacy"),
              t("landing.search.filters.Majors.nursing"),
              t("landing.search.filters.Majors.physiotherapy"),
              t("landing.search.filters.Majors.laboratory"),
              t("landing.search.filters.Majors.radiology"),
            ].map((major) => (
              <FilterBadge
                key={major}
                label={major}
                isActive={activeFilters.major === major}
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    major: prev.major === major ? null : major,
                  }))
                }
              />
            ))}
          </div>

          {/* Paid/Free Filters */}
          <div className="flex flex-wrap gap-2">
            <span className="text-sm text-gray-500 mr-2">
              {t("landing.search.filters.payment")}
            </span>
            <FilterBadge
              label={t("landing.search.filters.categories.paid")}
              isActive={activeFilters.paidFree === "Paid"}
              onClick={() =>
                setActiveFilters((prev) => ({
                  ...prev,
                  paidFree: prev.paidFree === "Paid" ? null : "Paid",
                }))
              }
            />
            <FilterBadge
              label={t("landing.search.filters.categories.free")}
              isActive={activeFilters.paidFree === "Free"}
              onClick={() =>
                setActiveFilters((prev) => ({
                  ...prev,
                  paidFree: prev.paidFree === "Free" ? null : "Free",
                }))
              }
            />
          </div>
        </div>

        {/* Map and Opportunities Grid */}
        <div className="grid lg:grid-cols-2 gap-4">
          {/* Map Container */}
          <div className="relative rounded-lg overflow-hidden shadow-lg h-[300px] lg:h-[calc(100vh-12rem)] z-0">
            <Map
              initialZoom={10}
              markers={filteredOpportunities
                .filter(
                  (opportunity) =>
                    opportunity.location.location &&
                    opportunity.location.location._lat !== undefined &&
                    opportunity.location.location._long !== undefined &&
                    opportunity?.seats > 0
                )
                .map((opportunity) => {
                  return {
                    position: [
                      opportunity.location.location._lat,
                      opportunity.location.location._long,
                    ],
                    popupContent: (
                      <OpportunityPopup opportunity={opportunity} />
                    ),
                  };
                })}
              mapStyle={{
                height: "100%",
                width: "100%",
                borderRadius: "0.5rem",
              }}
            />
          </div>

          {/* Opportunities List */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
              {t("landing.search.opportunities.title")}
            </h2>

            <div className="lg:h-[calc(100vh-16rem)] lg:overflow-y-auto">
              {loading ? (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-500 dark:text-gray-400">
                    {t("landing.search.opportunities.loading")}
                  </p>
                </div>
              ) : filteredOpportunities.length === 0 ? (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-500 dark:text-gray-400">
                    {t("landing.search.opportunities.noResults")}
                  </p>
                </div>
              ) : (
                <div className="grid gap-4">
                  {filteredOpportunities.map((opportunity) => (
                    <div
                      key={opportunity.id}
                      onClick={() => {
                        setSelectedOpportunity(opportunity);
                        setIsModalOpen(true);
                      }}
                      className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 hover:shadow-md transition-shadow cursor-pointer border border-gray-100 dark:border-gray-600"
                    >
                      <div className="flex gap-4">
                        <img
                          src={opportunity.location.image || placeholder}
                          alt={
                            i18n.language === "ar"
                              ? opportunity.location.name_ar ||
                                opportunity.location.name
                              : opportunity.location.name
                          }
                          className="w-24 h-24 object-contain rounded-lg bg-white dark:bg-white p-2"
                        />
                        <div className="flex-1">
                          <h3 className="font-semibold text-gray-800 dark:text-white">
                            {i18n.language === "ar"
                              ? opportunity.location.name_ar ||
                                opportunity.location.name
                              : opportunity.location.name}
                          </h3>
                          <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                            {i18n.language === "ar"
                              ? opportunity.name_ar || opportunity.name
                              : opportunity.name}
                          </p>
                          <p className="text-sm text-gray-500 dark:text-gray-400 mt-2 line-clamp-2">
                            {i18n.language === "ar"
                              ? opportunity.description_ar ||
                                opportunity.description_en
                              : opportunity.description_en}
                          </p>
                          <div className="mt-2">
                            {opportunity.startDate && (
                              <div>
                                {new Date(opportunity.startDate).toLocaleString(
                                  i18n.language === "ar" ? "ar-SA" : "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                    hour12: true,
                                  }
                                )}
                              </div>
                            )}
                            {opportunity.endDate && (
                              <div>
                                {new Date(opportunity.endDate).toLocaleString(
                                  i18n.language === "ar" ? "ar-SA" : "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                    hour12: true,
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Keep existing modal code */}
      {selectedOpportunity && isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
          <div className="fixed inset-0 bg-black/60 backdrop-blur-sm"></div>
          <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-2xl transform transition-all max-w-2xl w-full mx-4 my-8">
            {/* Header with close button */}
            <div className="sticky bottom-0 bg-gray-50 dark:bg-gray-700/50 px-6 py-4 rounded-t-xl">
              <div className="">
                <button
                  type="button"
                  className="rounded-lg p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                  onClick={() => setIsModalOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* Content */}
            {/* Content */}
            {modalContent}

            {/* Footer with action buttons */}
            {user && permissions?.id === "STUDENT" ? (
              <div className="sticky bottom-0 bg-gray-50 dark:bg-gray-700/50 px-6 py-4">
                <button
                  type="button"
                  className="w-full sm:w-auto inline-flex justify-center items-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                  onClick={() => {
                    setIsConfirmationModalOpen(true);
                  }}
                >
                  {applyButtonText}
                </button>
              </div>
            ) : (
              <div className="sticky bottom-0 bg-gray-50 dark:bg-gray-700/50 px-6 py-4 rounded-b-xl">
                <button
                  type="button"
                  className="w-full sm:w-auto inline-flex justify-center items-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                  onClick={() => {
                    setIsSignupModalOpen(true);
                  }}
                >
                  {applyButtonText}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {isConfirmationModalOpen && permissions?.id === "STUDENT" && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  // Handle confirm action here
                  handleApply();
                  setIsConfirmationModalOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
            <div className="p-4">
              <h1 className="text-2xl font-bold">Are you sure?</h1>
              <p>
                Do you want to apply to this opportunity? This process will be
                sent to the location for approval.
              </p>
            </div>
          </div>
        </div>
      )}
      {success && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="p-4">
              <h1 className="text-2xl font-bold">Success!</h1>
              <p>Your application has been successfully submitted.</p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setSuccess(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isRequestFormOpen &&
        selectedOpportunity &&
        permissions?.id === "STUDENT" && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative bg-white dark:bg-gray-800 rounded-lg p-6 max-w-2xl w-full m-4">
              <RequestForm
                formId={selectedOpportunity.form?.id}
                opportunityId={selectedOpportunity.id}
                studentId={user.id}
                onSubmit={handleFormSubmit}
                onCancel={() => setIsRequestFormOpen(false)}
              />
            </div>
          </div>
        )}
      {/* Date Conflict Modal */}
      {dateConflict && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              {/* Header */}
              <div className="flex items-center space-x-3 mb-4">
                <svg
                  className="w-8 h-8 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                  Unable to Apply
                </h1>
              </div>

              {/* Content */}
              <div className="space-y-4">
                <p className="text-gray-700 dark:text-gray-300">
                  This rotation overlaps with your existing schedule. You are
                  already assigned to:
                </p>
                <div className="bg-red-50 dark:bg-red-900/20 rounded-lg p-4 border border-red-100 dark:border-red-800">
                  <ul className="space-y-3">
                    {dateConflict.map((conflict, index) => (
                      <li
                        key={index}
                        className="text-gray-700 dark:text-gray-300"
                      >
                        <span className="font-medium">{conflict.name}</span>
                        <br />
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {conflict.dates}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Please select different dates or contact your administrator
                  for assistance.
                </p>
              </div>

              {/* Footer */}
              <div className="mt-6 flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 rounded-lg bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300 font-medium transition-colors duration-200"
                  onClick={() => setDateConflict(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Signup Modal */}
      <Modal
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
      >
        {/* <SignupPage opportunityId={selectedOpportunity?.id} /> */}
        <Contact opportunityId={selectedOpportunity?.id} />
      </Modal>
    </div>
  );
};

export default Search;
