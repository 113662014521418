import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocationContext } from "../../LocationContext";

const Sections = () => {
  const navigate = useNavigate();
  const { lifecycles, selectedLocation } = useContext(LocationContext);

  // // console.log(lifecycles);
  // // console.log(selectedLocation);

  // Merge locationSections with lifecycle stages
  // console.log("lifecycles", lifecycles);
  // console.log("selectedLocation", selectedLocation);
  const mergedSections =
    lifecycles?.[selectedLocation?.id]?.stages.flatMap((stage) => ({
      name: stage.name,
      stageName: stage.name,
      stageOrder: stage.order,
      tasks: stage.tasks || [],
      cards: stage.cards || [],
      color: stage.color,
    })) || [];

  // console.log("mergedSections", mergedSections);

  return (
    <div className="flex flex-wrap justify-between">
      {mergedSections
        .sort((a, b) => {
          if (Array.isArray(a.stageOrder) && Array.isArray(b.stageOrder)) {
            return a.stageOrder[0] - b.stageOrder[0];
          } else if (Array.isArray(a.stageOrder)) {
            return a.stageOrder[0] - b.stageOrder;
          } else if (Array.isArray(b.stageOrder)) {
            return a.stageOrder - b.stageOrder[0];
          } else {
            return a.stageOrder - b.stageOrder;
          }
        })
        .map((section) => {
          // console.log("Section:", section);
          return (
            <div
              key={section.stageOrder}
              className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
              style={{
                borderRight: "2px solid #e0e0e0",
                maxHeight: "80vh",
                height: "auto",
              }}
            >
              <h2 className="text-xl font-bold mb-2 flex items-center">
                <span className="mr-2" style={{ color: section.color }}>
                  {section.stageName}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  ({section.cards.length})
                </span>
              </h2>
              <div
                className="overflow-y-auto"
                style={{
                  maxHeight: "calc(100% - 40px)",
                  padding: "0 10px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#888 #e0e0e0",
                }}
              >
                <style>{`
                div::-webkit-scrollbar {
                  width: 8px;
                }
                div::-webkit-scrollbar-track {
                  background: #e0e0e0;
                }
                div::-webkit-scrollbar-thumb {
                  background-color: #888;
                  border-radius: 10px;
                  border: 2px solid #e0e0e0;
                }
              `}</style>
                {section.cards.map((card) => (
                  <div
                    key={card.id}
                    className="mb-4 p-2 bg-white dark:bg-gray-800 rounded-lg shadow-md cursor-pointer"
                    style={{
                      borderColor: section.color,
                      borderRight: `4px solid ${section.color}`,
                    }}
                    onClick={() => {
                      navigate(
                        `/app/university/${card.location.id}/${card.location.id}/card/${card.id}`
                      );
                    }}
                  >
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      {new Date(
                        card.opps.startDate.seconds * 1000 +
                          card.opps.startDate.nanoseconds / 1000000
                      ).toLocaleDateString()}{" "}
                      -{" "}
                      {new Date(
                        card.opps.endDate.seconds * 1000 +
                          card.opps.endDate.nanoseconds / 1000000
                      ).toLocaleDateString()}
                    </p>
                    <div className="flex items-center">
                      <img
                        src={card.location.image}
                        alt={card.location.name}
                        style={{ marginRight: "10px" }}
                        className="w-10 h-10 rounded-sm object-cover"
                      />
                      <div>
                        <h5 className="text-sm font-semibold dark:text-white">
                          {card.opps.location.name}
                        </h5>
                        <p className="text-xs text-gray-600 dark:text-gray-300">
                          {card.opps.name}
                        </p>
                        {card.stage && (
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            {card.stage.name} • {card.stage.tasks?.length || 0}{" "}
                            tasks
                          </p>
                        )}
                      </div>
                    </div>
                    <hr className="border-gray-300 dark:border-gray-700 my-1" />
                    <div className="flex items-center mt-2">
                      <div className="w-10 h-10 rounded-full mr-2 flex items-center justify-center bg-gray-500 dark:bg-gray-700 text-white font-bold">
                        {card.student?.name
                          ? card.student.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("")
                              .substring(0, 2)
                              .toUpperCase()
                          : ""}
                      </div>
                      <div>
                        <p className="text-sm font-medium dark:text-white">
                          {card.student.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Sections;
