import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    debug: false,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    language: "en",
    languages: {
      ar: { dir: "rtl", name: "العربية" },
      en: { dir: "ltr", name: "English" },
    },
  });

i18n.dir = function (lng = i18n.language) {
  return i18n.options.languages[lng]?.dir || "ltr";
};

export default i18n;
