import { useState } from "react";
import { useTranslation } from "react-i18next";
import { trackOpportunityInterest } from "../../services/firebaseService";
import { auth } from "../../configuration";
import Modal from "../Modal/Modal";
const Contact = ({ opportunityId, onClose }) => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "", 
    phone: ""
  });
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (opportunityId) {
      const userData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        userId: userId
      }
      trackOpportunityInterest(opportunityId, userData);
      setIsConfirmationModalOpen(true);
    }
  };

  return (
    <div className={`flex items-center justify-center bg-gray-50 dark:bg-gray-800 z-10 sm:px-6 lg:px-8 ${i18n.language === "ar" ? "rtl" : "ltr"}`}>
      <div className="max-w-800 w-full space-y-2">
        <div className="flex justify-center items-center mb-4">
          <h2 className="text-2xl text-white font-bold">
            {t("contact.form.title")}
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder={t("contact.form.name")}
            value={formData.name}
            onChange={handleChange}
            className={`block w-full p-2 mb-4 border border-gray-300 rounded-[7px] text-black ${
              i18n.language === "ar" ? "text-right" : "text-left"
            }`}
            required
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <input
            type="email"
            name="email"
            placeholder={t("contact.form.email")}
            value={formData.email}
            onChange={handleChange}
            className={`block w-full p-2 mb-4 border border-gray-300 rounded-[7px] text-black ${i18n.language === "ar" ? "placeholder:text-right" : "placeholder:text-left"}`}
            required
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <input
            type="tel"
            name="phone"
            placeholder={t("contact.form.phone")} 
            value={formData.phone}
            onChange={handleChange}
            className={`block w-full p-2 mb-4 border border-gray-300 rounded-[7px] text-black ${i18n.language === "ar" ? "placeholder:text-right" : "placeholder:text-left"}`}
            required
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          />
          <button
            type="submit"
            className="w-full p-2 bg-[#0e9065] text-white hover:bg-[#0b7a55] rounded-[7px] font-bold"
          >
            {t("contact.form.submit")}
          </button>
        </form>
      </div>

      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        from="Contact"
      >
        <p>{t("signup.confirmationModal.message")}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t("signup.confirmationModal.close")}
        </button>
      </Modal>

    </div>
  );
}

export default Contact;
