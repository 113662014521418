import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  HomeIcon,
  CalendarIcon,
  UserGroupIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
  ChatBubbleLeftRightIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  SunIcon,
  MoonIcon,
  ChartPieIcon,
  MapPinIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import logo from "../../assets/images/logo1.png";
import logo2 from "../../assets/images/logo2.png";
import SidebarLocations from "../Locations/SidebarLocations"; // Import SidebarLocations
import { signOut } from "firebase/auth";
import { auth } from "../../configuration";
import { UserContext } from "../../UserContext";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}




// Add this permission to navigation item mapping
const permissionMap = {
  Dashboard: "viewDashboard",
  Search: "viewSearchPage",
  Calendar: "viewCalendarPage",
  Rotations: "viewRotationsPage",
  Evaluations: "viewEvaluationsPage",
  Requests: "viewRequestsPage",
  Documents: "viewDocumentsPage",
  Reports: "viewReportsPage",
  "Students List": "viewStudentsListPage",
  Locations: "viewLocationsPage",
  Admin: "adminPanel",
  AlterDB: "alterDB",
};

// Add this constant for user types
const USER_TYPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  TRAINER: "TRAINER",
  UNIVERSITY: "UNIVERSITY",
  INSTITUTION: "INSTITUTION",
  STUDENT: "STUDENT",
  GUEST: "GUEST",
};

const NavBar = React.memo(
  ({ isDarkMode, setIsDarkMode, isSidebarOpen, setIsSidebarOpen }) => {
    const { user, permissions } = useContext(UserContext);
    const sidebarRef = useRef(null);
    const navigate = useNavigate();
    const { studentId } = useParams();
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [userType, setUserType] = useState(() => {
      return permissions?.id || USER_TYPES.GUEST;
    });
    const [baseId, setBaseId] = useState("1vln4o2CDpVwdGVEmLT6");
    const [locationId, setLocationId] = useState("");
    const { t, i18n } = useTranslation();

    // Update sidebarTranslations whenever the language changes
    const sidebarTranslations = useMemo(() => ({
      dashboard: t("sidebar.dashboard"),
      search: t("sidebar.search"),
      calendar: t("sidebar.calendar"),
      rotations: t("sidebar.rotations"),
      requests: t("sidebar.requests"),
      evaluations: t("sidebar.evaluations"),
      reports: t("sidebar.reports"),
      documents: t("sidebar.documents"),
      admin: t("sidebar.admin"),
      settings: t("sidebar.settings"),
      logout: t("sidebar.logout"),
      location: t("sidebar.location"),
      signIn: t("sidebar.signIn"),
      signUp: t("sidebar.signUp"),
    }), [t]);

    const handleLogout = async () => {
      setShowLogoutConfirm(true);
    };

    const confirmLogout = async () => {
      try {
        await signOut(auth);
        navigate("/app/login");
      } catch (error) {
        console.error("Error logging out:", error);
      }
      setShowLogoutConfirm(false);
    };

    // Update the navigation object to use USER_TYPES constants as keys
    // console.log("userType", userType);
    const navigation = {
      [USER_TYPES.STUDENT]: [
        {
          name: t("sidebar.dashboard"),
          href: `/app/dashboard`,
          icon: HomeIcon,
          current: false,
        },
        {
          name: t("sidebar.search"),
          href: `/app/search`,
          icon: MagnifyingGlassIcon,
          current: false,
        },
        {
          name: t("sidebar.calendar"),
          href: `/app/student-rotations`,
          icon: CalendarIcon,
          current: false,
        },
        {
          name: t("sidebar.evaluations"),
          href: `/app/evaluations`,
          icon: ClipboardDocumentCheckIcon,
          current: false,
        },
        {
          name: t("sidebar.requests"),
          href: `/app/student/requests`,
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.documents"),
          href: `/app/student/documents`,
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.reports"),
          href: `/app/student/reports`,
          icon: ChartPieIcon,
          current: false,
        },
        {
          name: t("sidebar.logout"),
          href: "#",
          icon: ArrowRightOnRectangleIcon,
          current: false,
          onClick: handleLogout,
        },
      ],
      [USER_TYPES.UNIVERSITY]: [
        {
          name: t("sidebar.dashboard"),
          href: `/app/university/dashboard`,
          icon: HomeIcon,
          current: false,
        },
        {
          name: t("sidebar.rotations"),
          href: `/app/university/rotations`,
          icon: CalendarIcon,
          current: false,
        },
        {
          name: t("sidebar.studentsList"),
          href: `/app/university/list`,
          icon: UserGroupIcon,
          current: false,
        },
        {
          name: t("sidebar.locations"),
          href: `/app/university/locations`,
          icon: MapPinIcon,
          current: false,
        },
        {
          name: t("sidebar.requests"),
          href: `/app/university/requests`,
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.evaluations"),
          href: `/app/evaluations`,
          icon: ClipboardDocumentCheckIcon,
          current: false,
        },
        {
          name: t("sidebar.documents"),
          href: `/app/university/documents`,
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.reports"),
          href: `/app/university/reports`,
          icon: ChartPieIcon,
          current: false,
        },
        {
          name: t("sidebar.logout"),
          href: "#",
          icon: ArrowRightOnRectangleIcon,
          current: false,
          onClick: handleLogout,
        },
        {
          name: "AlterDB",
          href: `/app/university/alterdb`,
          icon: ArrowRightOnRectangleIcon,
          current: false,
        },
      ],
      [USER_TYPES.INSTITUTION]: [
        {
          name: t("sidebar.dashboard"),
          href: "/app/institution/dashboard",
          icon: HomeIcon,
          current: false,
        },
        {
          name: t("sidebar.rotations"),
          href: "/app/rotations",
          icon: CalendarIcon,
          current: false,
        },
        {
          name: t("sidebar.requests"),
          href: "/app/requests",
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.evaluations"),
          href: "/app/evaluations",
          icon: ClipboardDocumentCheckIcon,
          current: false,
        },
        {
          name: t("sidebar.reports"),
          href: "/app/reports",
          icon: ChartPieIcon,
          current: false,
        },
        {
          name: t("sidebar.logout"),
          href: "#",
          icon: ArrowRightOnRectangleIcon,
          current: false,
          onClick: handleLogout,
        },
      ],
      [USER_TYPES.TRAINER]: [
        {
          name: t("sidebar.rotations"),
          href: "/app/rotations",
          icon: CalendarIcon,
          current: false,
        },
        {
          name: t("sidebar.evaluations"),
          href: "/app/evaluations",
          icon: ClipboardDocumentCheckIcon,
          current: false,
        },
        {
          name: t("sidebar.logout"),
          href: "#",
          icon: ArrowRightOnRectangleIcon,
          current: false,
          onClick: handleLogout,
        },
      ],
      [USER_TYPES.ADMIN]: [
        {
          name: t("sidebar.dashboard"),
          href: `/app/university/dashboard`,
          icon: HomeIcon,
          current: false,
        },
        {
          name: t("sidebar.rotations"),
          href: `/app/rotations`,
          icon: CalendarIcon,
          current: false,
        },
        {
          name: t("sidebar.requests"),
          href: `/app/requests`,
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.evaluations"),
          href: `/app/evaluations`,
          icon: ClipboardDocumentCheckIcon,
          current: false,
        },
        {
          name: t("sidebar.documents"),
          href: `/app/documents`,
          icon: DocumentTextIcon,
          current: false,
        },
        {
          name: t("sidebar.reports"),
          href: `/app/reports`,
          icon: ChartPieIcon,
          current: false,
        },
        {
          name: t("sidebar.logout"),
          href: "#",
          icon: ArrowRightOnRectangleIcon,
          current: false,
          onClick: handleLogout,
        },
        {
          name: t("sidebar.admin"),
          href: "/app/admin/7x4b2k-h9z83p",
          icon: Cog6ToothIcon,
          current: false,
        },
      ],
      [USER_TYPES.GUEST]: [
        {
          name: t("sidebar.signIn"),
          href: "/app/login",
          icon: ArrowRightOnRectangleIcon,
          current: false,
        },
        {
          name: t("sidebar.signUp"),
          href: "/app/signup",
          icon: ArrowRightOnRectangleIcon,
          current: false,
        },
      ],
    };

    // Add SUPER_ADMIN navigation by combining all other navigation items
    navigation[USER_TYPES.SUPER_ADMIN] = [
      // Filter out logout from the combined navigation items
      ...navigation[USER_TYPES.INSTITUTION].filter(item => item.name !== t("sidebar.logout")),
      ...navigation[USER_TYPES.STUDENT].filter(item => item.name !== t("sidebar.logout")), 
      ...navigation[USER_TYPES.ADMIN].filter(item => item.name !== t("sidebar.logout")),
      // Add logout as the last item
      navigation[USER_TYPES.INSTITUTION].find(item => item.name === t("sidebar.logout"))
    ].filter(
      (item, index, self) =>
        // Remove duplicates based on name
        index === self.findIndex((t) => t.name === item.name)
    );

    // Update the filterNavigationByPermissions function
    const filterNavigationByPermissions = (navItems) => {
      if (!navItems) {
        return []; // Return empty array if navItems is undefined
      }

      // SUPER_ADMIN and ALL permission users get all navigation items
      if (
        permissions?.ALL ||
        userType === USER_TYPES.SUPER_ADMIN ||
        userType === USER_TYPES.ADMIN
      ) {
        return navItems;
      }

      return navItems.filter((item) => {
        // Always show logout, sign in, and sign up
        if (
          item.name === sidebarTranslations.logout ||
          item.name === sidebarTranslations.signIn ||
          item.name === sidebarTranslations.signUp 
        ) {
          return true;
        }

        const requiredPermission = permissionMap[item.name];
        return requiredPermission ? permissions?.[requiredPermission] : false;
      });
    };

    // Memoize handlers with useCallback
    const toggleSidebar = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSidebarOpen((prev) => !prev);
      },
      [setIsSidebarOpen]
    );

    const toggleDarkMode = useCallback(() => {
      setIsDarkMode((prev) => !prev);
    }, [setIsDarkMode]);

    // Memoize filtered navigation
    const filteredNavigation = useMemo(() => {
      const navItems = filterNavigationByPermissions(
        navigation[userType] || navigation[USER_TYPES.GUEST]
      );
      return navItems;
    }, [userType, permissions, i18n.language]);

    // Move resize handler outside useEffect and memoize it
    const handleResize = useCallback(() => {
      if (window.innerWidth >= 640) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    }, []);

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    useEffect(() => {
      setUserType(permissions?.id || USER_TYPES.GUEST);
    }, [permissions?.id]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          sidebarRef.current &&
          !sidebarRef.current.contains(event.target) &&
          isSidebarOpen &&
          window.innerWidth < 640
        ) {
          setIsSidebarOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isSidebarOpen]);

    // Update user switch functions in dropdown menu
    const userSwitchOptions = [
      {
        label: "Switch to Student User",
        type: USER_TYPES.STUDENT,
        path: "/app/student/16WPS1VBONBrghn1hR3h/search",
      },
      {
        label: "Switch to University User",
        type: USER_TYPES.UNIVERSITY,
        path: "/app/university/1vln4o2CDpVwdGVEmLT6/rotations",
      },
      {
        label: "Switch to Institution User",
        type: USER_TYPES.INSTITUTION,
        path: "/app/institution/1vln4o2CDpVwdGVEmLT6/57CvXtgz97q1iXcVD5uG/rotations",
      },
    ];

    return (
      <>
        {showLogoutConfirm && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
            <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-sm w-full mx-4">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Confirm Logout
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">
                  Are you sure you want to log out?
                </p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={() => setShowLogoutConfirm(false)}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none"
                  >
                    <XMarkIcon className="h-5 w-5 mr-2" />
                    Cancel
                  </button>
                  <button
                    onClick={confirmLogout}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                  >
                    <CheckIcon className="h-5 w-5 mr-2" />
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start rtl:justify-end">
                <button
                  data-drawer-target="logo-sidebar"
                  data-drawer-toggle="logo-sidebar"
                  aria-controls="logo-sidebar"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  onClick={toggleSidebar}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="w-6 h-6" />
                </button>
                <Link to="/" className="flex ms-2 md:me-24">
                  <img
                    src={isDarkMode ? logo : logo2}
                    className="h-10 me-3"
                    alt="Logo"
                  />
                  <span className="self-center text-xl font-semibold px-2 sm:text-2xl whitespace-nowrap dark:text-white"></span>
                </Link>
              </div>
              <div className="flex items-center">
                <LanguageSwitcher />
                <button
                  onClick={toggleDarkMode}
                  className="p-2 text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                >
                  {isDarkMode ? (
                    <SunIcon className="w-6 h-6" />
                  ) : (
                    <MoonIcon className="w-6 h-6" />
                  )}
                  <span className="sr-only">Toggle dark mode</span>
                </button>
                <div className="relative flex items-center ms-3">
                  <div>
                    <button
                      type="button"
                      className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                      aria-expanded={userDropdownOpen}
                      onClick={() => setUserDropdownOpen(!userDropdownOpen)}
                    >
                      <span className="sr-only">Open user menu</span>
                      <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center text-gray-600 dark:text-gray-300 text-sm font-semibold">
                        {user?.name
                          ? user.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("")
                          : `${user?.firstName?.[0] || ""}${
                              user?.middleName?.[0] || ""
                            }${user?.lastName?.[0] || ""}`}
                      </div>
                    </button>
                  </div>
                  {userDropdownOpen && (
                    <div
                      className="absolute top-10 right-0 z-50 mt-2 w-48 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                      id="dropdown-user"
                    >
                      <div className="px-4 py-3" role="none">
                        <p
                          className="text-sm text-gray-900 dark:text-white"
                          role="none"
                        >
                          {user?.name}
                        </p>
                        <p
                          className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                          role="none"
                        >
                          {user?.email}
                        </p>
                      </div>
                      <ul className="py-1" role="none">
                        {permissions?.ALL && (
                          <>
                            {userSwitchOptions.map((option) => (
                              <li key={option.type}>
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                  role="menuitem"
                                  onClick={() => {
                                    navigate(option.path);
                                    setUserDropdownOpen(false);
                                  }}
                                >
                                  {option.label}
                                </a>
                              </li>
                            ))}
                            {(permissions?.ALL || permissions?.adminPanel) && (
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                  role="menuitem"
                                  onClick={() => {
                                    navigate(`/app/admin/7x4b2k-h9z83p`);
                                    setUserDropdownOpen(false);
                                  }}
                                >
                                  Admin panel
                                </a>
                              </li>
                            )}
                          </>
                        )}
                        {user && (
                          <li>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                              role="menuitem"
                              onClick={handleLogout}
                            >
                              Sign out
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>

        <aside
          id="logo-sidebar"
          className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
          aria-label="Sidebar"
          ref={sidebarRef}
          style={{ paddingTop: "5rem" }}
        >
          <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
            {(permissions?.id === USER_TYPES.UNIVERSITY ||
              permissions?.id === USER_TYPES.INSTITUTION ||
              permissions?.id === USER_TYPES.SUPER_ADMIN ||
              permissions?.id === USER_TYPES.ADMIN) && (
              <SidebarLocations
                setBaseId={setBaseId}
                setLocationId={setLocationId}
              />
            )}
            <ul className="space-y-2 font-medium">
              {filteredNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    onClick={item.onClick}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white"
                        : "text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white",
                      "flex items-center p-2 rounded-lg group"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-900 dark:text-white"
                          : "text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white",
                        "w-5 h-5 transition duration-75"
                      )}
                      aria-hidden="true"
                    />
                    <span className="ms-3">{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isDarkMode === nextProps.isDarkMode &&
      prevProps.isSidebarOpen === nextProps.isSidebarOpen
    );
  }
);

export default NavBar;
