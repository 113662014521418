import React from 'react';

const Modal = ({ isOpen, onClose, children, from = null }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm"></div>
      <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-2xl transform transition-all max-w-2xl w-full mx-4 my-8">
        {from !== 'Contact' && (  
          <div className="sticky top-0 right-0 pt-4 pr-4 bg-white dark:bg-gray-800 z-10 rounded-t-xl">
            <button
              type="button"
              className="rounded-lg p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        )}
        <div className='p-6 overflow-y-auto max-h-[calc(100vh-4rem)]'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;