// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzJCAOkJm9qzzoqYq7RQb6nt54LQI2c3s",
  authDomain: "rota-7b68a.firebaseapp.com",
  projectId: "rota-7b68a",
  storageBucket: "rota-7b68a.appspot.com",
  messagingSenderId: "724786079354",
  appId: "1:724786079354:web:3e4f34cfd2270d57fc3dc3",
  measurementId: "G-MP2F6LLMCC",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Function to log UTM parameters
export const logUtmParameters = (utmSource, utmMedium, utmCampaign) => {
  logEvent(analytics, "utm_tracking", {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  });
};

export { db, auth, analytics };
