import React, { useState, useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { getVisitorStats, getOpportunityInterests, getOppById, getTrafficSourceStats } from "../services/firebaseService";

const AdminSiteStats = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    visitorCount: 0,
    returningVisitorCount: 0,
    opportunityInterests: [],
    trafficSourceStats: {}
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const visitorStats = await getVisitorStats();
        const oppInterests = await getOpportunityInterests();
        const trafficSourceStats = await getTrafficSourceStats();
        setStats({
          visitorCount: visitorStats.visitorCount || 0,
          returningVisitorCount: visitorStats.returningVisitorCount || 0,
          opportunityInterests: oppInterests || [],
          trafficSourceStats: trafficSourceStats || {}
        });
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <button
        onClick={() => window.history.back()}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2 mb-8"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        Back
      </button>

      {loading ? (
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-700 dark:border-white mx-auto"></div>
        </div>
      ) : (
        <div className="space-y-8">
          {/* Visitor Stats Card */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-8">
            <h2 className="text-xl text-gray-900 dark:text-white text-center mb-8">Visitor Statistics</h2>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div className="text-center">
                <p className="text-4xl font-bold text-gray-900 dark:text-white">{stats.visitorCount}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-2">Total Visitors</p>
              </div>
              <div className="text-center">
                <p className="text-4xl font-bold text-gray-900 dark:text-white">{stats.returningVisitorCount}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-2">Repeat Visitors</p>
              </div>
            </div>

            {/* New Traffic Sources Section */}
            <h3 className="text-lg text-gray-900 dark:text-white text-center mb-4">Traffic Sources</h3>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
              <div className="text-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.trafficSourceStats.hospitals || 0}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-1">Hospitals</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.trafficSourceStats.tiktok || 0}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-1">TikTok</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.trafficSourceStats.instagram || 0}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-1">Instagram</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.trafficSourceStats.twitter || 0}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-1">Twitter</p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.trafficSourceStats.website || 0}</p>
                <p className="text-gray-500 dark:text-gray-400 mt-1">Website</p>
              </div>
            </div>
          </div>

          {/* Interests Table Card */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-8">
            <h2 className="text-xl text-gray-900 dark:text-white text-center mb-8">Interests by Opportunity</h2>
            <div className="overflow-x-auto">
              <table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">User Name</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Email</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Phone</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Opportunity</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Location</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Price</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Source</th>
                    <th className="text-left py-3 px-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">User ID</th>
                   
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                  {stats.opportunityInterests.map((opp, index) => (
                    <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors">
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp.userName}</td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp.userEmail}</td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp.userPhone}</td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp?.opportunity?.name || "Not Found"}</td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp.location}</td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">
                        {opp.price ? (
                          opp.price === "Unpaid" ? (
                            <span className="text-red-500">FREE</span>
                          ) : (
                            <span className="text-green-500">{opp.price}</span>
                          )
                        ) : (
                          "Not Found"
                        )}
                      </td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp.source}</td>
                      <td className="py-4 px-4 text-sm text-gray-900 dark:text-gray-100">{opp?.userId.id || "Not Found"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSiteStats;
