import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "./Map.css";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = ({ initialZoom = 13, markers = [], mapStyle = {} }) => {
  const [center, setCenter] = useState([25.4858, 39.1925]); // Default to Jeddah
  const [zoom, setZoom] = useState(initialZoom);
  const [isLoading, setIsLoading] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const [cityName, setCityName] = useState("");
  const mapRef = useRef();

  useEffect(() => {
    const getUserLocation = async () => {
      if ("geolocation" in navigator) {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });

          const userLocation = [
            position.coords.latitude,
            position.coords.longitude,
          ];
          setCenter(userLocation);
          setLocationError(false);
          setIsLoading(false);
        } catch (error) {
          console.error("Error getting user location:", error);
          setLocationError(true);
          setIsLoading(false);
        }
      } else {
        // console.log("Geolocation is not available in this browser.");
        setLocationError(true);
        setIsLoading(false);
      }
    };

    getUserLocation();
  }, []);

  useEffect(() => {
    if (mapRef.current && !isLoading) {
      mapRef.current.setView(center, zoom);
    }
  }, [center, zoom, isLoading]);

  const defaultMapStyle = { height: "90vh", width: "100%" };
  const combinedMapStyle = { ...defaultMapStyle, ...mapStyle };

  const handleEnableLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = [
          position.coords.latitude,
          position.coords.longitude,
        ];
        setCenter(userLocation);
        setLocationError(false);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error getting user location:", error);
        setLocationError(true);
        setIsLoading(false);
      }
    );
  };

  const createClusterCustomIcon = (cluster) => {
    return L.divIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: L.point(40, 40, true),
    });
  };

  if (isLoading) {
    return <div>Loading map...</div>;
  }

  if (locationError) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="mb-4">Please enable location services to use the map</p>
        <button
          onClick={handleEnableLocation}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Enable Location
        </button>
      </div>
    );
  }

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={combinedMapStyle}
      whenCreated={(mapInstance) => {
        mapRef.current = mapInstance;
        mapInstance.on("moveend", () => {
          setCenter(mapInstance.getCenter());
          setZoom(mapInstance.getZoom());
        });
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createClusterCustomIcon}
        maxClusterRadius={60}
        spiderfyOnMaxZoom={true}
        showCoverageOnHover={false}
      >
        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position}>
            <Popup>
              <div className="cluster-popup">{marker.popupContent}</div>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default Map;
