import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLang);
  };

  return (
    <button
      onClick={toggleLanguage}
      className="flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200 
      bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600
      text-gray-700 dark:text-gray-200 font-medium"
    >
      <span className="text-lg">{i18n.language === "en" ? "🇸🇦" : "🇺🇸"}</span>
      <span className={`${i18n.language === "ar" ? "font-arabic" : ""}`}>
        {i18n.language === "en" ? "عربي" : "English"}
      </span>
    </button>
  );
};

export default LanguageSwitcher;
