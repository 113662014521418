import React, { useState } from "react";
import {
  getRotationApplicationsCount,
  getStudentsAppliedCount,
  getAcceptedRotationsCount,
  getStudentsWithAcceptedRotationsCount,
  getStudentFacultyPreceptorsReport,
  getPerformanceUtilizationReport,
} from "../../services/firebaseService";
import { utils, writeFile } from "xlsx";

const ReportCard = ({ Icon, label, onClick }) => (
  <div
    onClick={onClick}
    className="flex flex-col items-center p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
  >
    <Icon className="w-12 h-12 mb-4 text-blue-500 dark:text-blue-400" />
    <span className="text-gray-700 dark:text-gray-300 font-medium">
      {label}
    </span>
  </div>
);

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-3xl w-full max-h-[80vh] overflow-auto">
        <h2 className="text-2xl font-bold mb-4">{title}</h2>
        {children}
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Reports = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [reportData, setReportData] = useState([]);

  const fetchReportData = async (reportFunction) => {
    const data = await reportFunction();
    // // console.log(data);
    setReportData(data);
  };

  const openModal = (report) => {
    setCurrentReport(report);
    fetchReportData(report.dataFunction);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentReport(null);
    setReportData([]);
  };

  const exportToExcel = () => {
    const ws = utils.json_to_sheet(reportData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, `${currentReport.label}.xlsx`);
  };

  const reports = [
    {
      Icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
          <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
          <path d="M9 14h6"></path>
          <path d="M9 18h6"></path>
          <path d="M9 10h6"></path>
        </svg>
      ),
      label: "Rotation Applications Count",
      dataFunction: getRotationApplicationsCount,
    },
    {
      Icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
      ),
      label: "Students Applied Count",
      dataFunction: getStudentsAppliedCount,
    },
    {
      Icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
      ),
      label: "Accepted Rotations Count",
      dataFunction: getAcceptedRotationsCount,
    },
    {
      Icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="8.5" cy="7" r="4"></circle>
          <polyline points="17 11 19 13 23 9"></polyline>
        </svg>
      ),
      label: "Students with Accepted Rotations",
      dataFunction: getStudentsWithAcceptedRotationsCount,
    },
    {
      Icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
      ),
      label: "Student, Faculty and Preceptors Report",
      dataFunction: getStudentFacultyPreceptorsReport,
    },
    {
      Icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="20" x2="18" y2="10"></line>
          <line x1="12" y1="20" x2="12" y2="4"></line>
          <line x1="6" y1="20" x2="6" y2="14"></line>
        </svg>
      ),
      label: "Performance and Utilization Report",
      dataFunction: getPerformanceUtilizationReport,
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">
        Reports
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {reports.map((report, index) => (
          <ReportCard
            key={index}
            Icon={report.Icon}
            label={report.label}
            onClick={() => openModal(report)}
          />
        ))}
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        title={currentReport?.label}
      >
        {reportData.length > 0 ? (
          <>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  {Object.keys(reportData[0]).map((key) => (
                    <th
                      key={key}
                      className="border-b dark:border-gray-600 font-medium p-4 pl-8 pt-0 pb-3 text-gray-400 dark:text-gray-200 text-left"
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reportData.map((row, i) => (
                  <tr key={i}>
                    {Object.values(row).map((value, j) => (
                      <td
                        key={j}
                        className="border-b border-gray-200 dark:border-gray-600 p-4 pl-8 text-gray-500 dark:text-gray-400"
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={exportToExcel}
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mr-4"
            >
              Export to Excel
            </button>
          </>
        ) : (
          <p>Loading data...</p>
        )}
      </Modal>
    </div>
  );
};

export default Reports;
